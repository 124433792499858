import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { RealEstatePartner } from "../lib/types";
import LinkButton from "./button";

export interface PartnerCardProps {
  partner: RealEstatePartner;
}
const PartnerCard: React.FC<PartnerCardProps> = ({ partner }) => (
  <div className="grid shadow-center rounded-md md:grid-cols-3 xl:grid-cols-5">
    {/* Image */}
    <div
      className="flex-col
        flex
        col-span-2
        bg-light-100
        justify-between pt-8
        rounded-t-md
        md:rounded-l-md
        md:rounded-t-none
        md:col-span-1
        "
    >
      <p
        className={
          "font-bold p-1 text-center w-full bg-dark-100 text-white -rotate-6 -skew-x-6"
        }
      >
        {partner.sector}
      </p>

      <div
        className={
          "flex flex-col h-full w-full justify-center items-center px-8"
        }
      >
        {partner.logo.localFile.childImageSharp ? (
          <GatsbyImage
            image={getImage(partner.logo.localFile)}
            alt={`Logo ${partner.name}`}
            className=" h-40 w-64 md:block"
            objectFit={"contain"}
          />
        ) : (
          <img
            src={partner.logo.localFile.publicURL}
            alt={`Logo ${partner.name}`}
            className=" object-contain h-40 w-64  md:block"
          />
        )}
      </div>
    </div>

    {/* Description */}
    <div className="col-span-2 flex flex-col space-y-4 p-4 xl:col-span-4">
      <span className="font-bold italic">{partner.catchphrase}</span>

      <p>{partner.description}</p>

      <div className={"inline-block py-2"}>
        <LinkButton
          label="En savoir plus"
          type="secondary"
          href={
            partner.directLink
              ? partner.directLink
              : `/optimiser-votre-projet/${partner.SEO.slug}`
          }
          external={!!partner.directLink}
        />
      </div>
    </div>
  </div>
);

export default PartnerCard;

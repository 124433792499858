import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { GatsbyImageType } from "../lib/types";
import HeadTitle from "./headtitle";

export interface CategoryHeaderProps {
  title: string;
  cover: { localFile?: GatsbyImageType };
  className?: string;
}

const CategoryHeader: React.FC<CategoryHeaderProps> = ({
  title,
  cover,
  className = "",
}) => (
  <div className={`relative h-60 xl:h-80 ${className}`}>
    {cover &&
      (cover.localFile?.childImageSharp ? (
        <GatsbyImage
          image={getImage(cover.localFile.childImageSharp)}
          alt={title}
          className="absolute inset-0 object-cover w-full h-full"
        />
      ) : (
        <img
          src={cover.localFile?.publicURL}
          alt={title}
          className="object-cover w-full h-full"
        />
      ))}
    <div className="absolute inset-0 h-full flex items-center px-5 md:px-15">
      <HeadTitle
        as="h1"
        className="text-lg sm:text-2xl xl:text-3xl xxl:text-4xl"
        breakWords={true}
        label={title}
      />
    </div>

    {/* The Shadow of the wave */}
    <Wave
      className="absolute
       left-0 right-0 fill-current text-gray-800/10 blur-sm
       -bottom-2
       sm:-bottom-4
       lg:-bottom-7
       xl:-bottom-12
       xxl:-bottom-10
       "
    />

    {/* The wave */}
    <Wave
      className="absolute
        left-0 right-0 fill-current text-secondary-100
        -bottom-1.5
        sm:-bottom-3
        lg:-bottom-8
        xl:-bottom-10
        xxl:-bottom-12
        "
    />
  </div>
);

export default CategoryHeader;

const Wave: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    data-name="Calque 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1227 61.41"
    {...props}
  >
    <path d="M0 20.57S466.65 10.09 673.25.93C781.6-3.88 1227 11.57 1227 11.57v32S650.58 48.64 496.42 54c0 0-277.48 16.33-496.42.6z" />
  </svg>
);

import { Link } from "gatsby";
import * as React from "react";

export const ButtonTypes = ["cta", "secondary", "light"] as const;

export const ButtonSizes = ["sm", "md", "lg"] as const;

export interface LinkButtonProps {
  label?: string;
  type?: typeof ButtonTypes[number];
  href?: string;
  className?: string;
  size?: typeof ButtonSizes[number];
  external?: boolean;
  target?: "_self" | "_blank" | "_parent" | "_top";
  ariaLabel?: string;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  href = "#",
  label = "",
  type = "cta",
  className = "",
  size = "md",
  external = false,
  target = "_blank",
  ariaLabel = label,
}) => {
  const sizes = {
    sm: "px-2 py-2 text-sm",
    md: "px-2 py-3 text-base",
    lg: "px-3 py-3.5 text-lg",
  };
  return (
    <>
      {external ? (
        <a
          aria-label={ariaLabel}
          className={`${sizes[size]} rounded-lg font-bold ${className} ${
            type === "cta"
              ? "bg-primary-100 text-white hover:shadow-primary"
              : "bg-secondary-100 text-white  hover:shadow-secondary"
          }`}
          href={href}
          target={target}
        >
          {label}
        </a>
      ) : (
        <Link
          aria-label={ariaLabel}
          className={`${sizes[size]} rounded-lg font-bold ${className} ${
            type === "cta"
              ? "bg-primary-100 text-white hover:shadow-primary"
              : type === "secondary"
              ? "bg-secondary-100 text-white  hover:shadow-secondary"
              : " bg-light-100 text-secondary-100 hover:shadow-light"
          }`}
          to={href}
        >
          {label}
        </Link>
      )}
    </>
  );
};

export default LinkButton;

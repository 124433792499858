import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";

// components
import CategoryHeader from "../../components/categoryheader";
import Layout from "../../components/layout";
import PartnerCard from "../../components/partnercard";
import Seo from "../../components/seo";

// types & interfaces
import type {
  GatsbyImageType,
  PageProps,
  RealEstatePartner,
  SEO,
} from "../../lib/types";

interface OptimizePageProps {
  SEO: Pick<SEO, "title" | "description">;
  title: string;
  description: string;
  cover: { localFile: GatsbyImageType };
  illustrationCta: { localFile: GatsbyImageType };
}

type OptimizePageData = {
  partners: { all: Array<RealEstatePartner> };
};

const OptimizePage: React.FC<PageProps<OptimizePageProps, OptimizePageData>> =
  ({
    data: {
      page: { SEO, title, description, cover, illustrationCta },
      partners: { all: partners },
    },
    location: { pathname },
  }) => (
    <>
      <Layout active={"#infos"}>
        <Seo {...SEO} pathname={pathname} />
        <main className="mb-40 mt-15 sm:mb-40 lg:mt-20">
          <CategoryHeader title={title} cover={cover} />

          <section className="space-y-8 m-auto px-5 md:px-10 lg:px-15">
            <p className={"mt-8 lg:mt-14"}>{description}</p>

            <h2 className="text-xl font-bold">
              Vous pouvez leur faire confiance :
            </h2>

            <div className="space-y-10">
              {partners.map((partner, i) => (
                <React.Fragment key={i}>
                  <PartnerCard partner={partner} />
                </React.Fragment>
              ))}
            </div>
          </section>

          <section className="mt-10 m-auto px-5 md:px-10 lg:px-15 xl:px-24">
            <a href="mailto:teamcourtiers@monemprunt.com" target="_blank">
              {illustrationCta &&
                (illustrationCta?.localFile?.childImageSharp ? (
                  <GatsbyImage
                    image={getImage(
                      illustrationCta?.localFile?.childImageSharp
                    )}
                    alt={title}
                    className="object-cover w-full h-full"
                  />
                ) : (
                  <img
                    src={illustrationCta?.localFile?.publicURL}
                    alt={title}
                    className="object-cover w-full h-full"
                  />
                ))}
            </a>
          </section>
        </main>
      </Layout>
    </>
  );

export default OptimizePage;

export const query = graphql`
  query pageOptimisation {
    page: strapiPageOptimisezVotreProjet {
      title
      description
      SEO {
        description
        title
      }
      cover {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }

      illustrationCta {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    }

    partners: allStrapiPartenaireImmobilier {
      all: nodes {
        name
        sector
        description
        directLink: direct_link
        catchphrase
        SEO {
          description
          slug
          title
        }
        logo {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }
`;
